/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import { Paper, Grid, Typography, Select, MenuItem, IconButton, Popover, Tooltip, TextField, DialogActions, Button } from '@mui/material';
import { PlayArrow, Publish, Info } from '@mui/icons-material';
import LeftArrow from '@mui/icons-material/KeyboardArrowLeft';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider, MobileDatePicker, PickersDay } from '@mui/x-date-pickers';
import customStyles from './CameraRecordings.module.scss';
import AppLoader from '../../../components/AppLoader';
import DownloadRecordings from './DownloadRecordings';
import SeekBar from '../../../components/DashCam/VideoPlayer/SeekBar';
import VideoPlayer from '../../../components/DashCam/VideoPlayer/VideoPlayer';
import { getRecordingAvailability, prepareRecording } from '../helper-classes/dashcamServices';
import {
    isDashcamAuthenticated, getTimeInMinutes, getTimeToDisplayAsPerAccSettings,
    getMediaSourceUrl, getDeviceMediaTokenData, videoPlayPromise, getDashcamToken, getDashcamOrgId,
} from '../../../util/dashcam_util';
import {
    convertDateTime,
    getAppUserSettings,
} from '../../../helper-classes/utility-functions';
import {
    RECORDING_VIDEO_DURATION_IN_SEC,
    DASHCAM_ANALYTICS,
    DASHCAM_RECORDING,
    NORMAL_QUALITY_TOOLTIP,
    HIGH_QUALITY_TOOLTIP,
    VIDEO_FAST_PLAYBACK_RATE,
    VIDEO_NORMAL_PLAYBACK_RATE,
    HIGH_QUALITY_TEXT,
    NORMAL_QUALITY_TEXT,
} from '../../../containers/DashCam/constants.dashcam';
import analytics from '../../../analytics/index';

export type Props = {
    selectedDevice: Object,
    imei: string,
    showNotification: Function,
};

export type State = {
    dashcamRecordingRanges: Array<Object>,
    recordingDateRanges: Array<string>,
    selectedRecordingDate: Object,
    selectedRecordingMonth: number,
    selectedRecordingYear: number,
    selectedCameraId: number | typeof undefined,
    selectedCameraName: string,
    selectedIntervals: Array<Object>,
    isRecordingAvailable: boolean,
    timeSelected: {
        start: number,
        startTimeStamp: number,
        timeToDisplay: string,
    },
    showVideoPlayer: boolean,
    isPlaying: boolean,
    isRecordingAutoPaused: boolean,
    playbackUrl: string,
    qualityLevel: string,
    startTime: number,
    anchorEl: null,
    isUpdating: boolean,
    preparingRecording: boolean,
    streamDetails: string,
    datePickerOpen: boolean,
    pickedDate: Date,
};

const cameraIdMapping = [
    { cameraId: 1, label: 'Front Camera' },
    { cameraId: 2, label: 'Rear Camera' },
];

class CameraRecordings extends Component<Props, State> {
    playerRef: Object;
    timelineRef: Object;
    autoRefreshTimer: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            dashcamRecordingRanges: [],
            recordingDateRanges: [],
            selectedRecordingDate: moment(),
            selectedRecordingMonth: moment().month(),
            selectedRecordingYear: moment().year(),
            selectedCameraId: undefined,
            selectedCameraName: '',
            selectedIntervals: [],
            isRecordingAvailable: false,
            timeSelected: {
                start: 0,
                startTimeStamp: moment().unix(),
                timeToDisplay: getTimeToDisplayAsPerAccSettings(0),
            },
            showVideoPlayer: false,
            isPlaying: false,
            isRecordingAutoPaused: false,
            playbackUrl: '',
            qualityLevel: 'lq',
            startTime: 0,
            anchorEl: null,
            isUpdating: true,
            preparingRecording: false,
            streamDetails: '',
            datePickerOpen: false,
            pickedDate: moment(),
        };
        this.playerRef = React.createRef();
        this.timelineRef = React.createRef();
        this.autoRefreshTimer = null;
    }

    componentDidMount() {
        if (isDashcamAuthenticated()) {
            this.getRecordingSlots({ imei: this.props.imei, start: this.getRecRangeDates('start'), end: this.getRecRangeDates('end') });
            this.trackAnalytics();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (isDashcamAuthenticated() && (nextProps.imei !== this.props.imei)) {
            this.setState({
                isUpdating: true,
                startTime: 0,
                playbackUrl: '',
                showVideoPlayer: false,
                preparingRecording: false,
                isPlaying: true,
            }, () => {
                this.getRecordingSlots({
                    imei: nextProps.imei,
                    start: this.getRecRangeDates('start'),
                    end: this.getRecRangeDates('end'),
                });
            });
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    getRecRangeDates = (
        type: string,
        selectedYear: number | null = null,
        selectedMonth: number | null = null,
    ) => {
        if (selectedYear !== null && selectedMonth !== null) {
            return ((type === 'start') ? moment().set({ year: selectedYear, month: selectedMonth }).tz(getAppUserSettings('userTz')).startOf('month')
                .toISOString() : moment().tz(getAppUserSettings('userTz')).endOf('day')
                .toISOString());
        }

        return ((type === 'start') ? moment().subtract(6, 'month').tz(getAppUserSettings('userTz')).startOf('month')
            .toISOString() : moment().tz(getAppUserSettings('userTz')).endOf('day').toISOString());
    }

    getSelectedCameraText = (cameraId: number) => {
        if (cameraIdMapping[0].cameraId === cameraId) return cameraIdMapping[0].label;
        if (cameraIdMapping[1].cameraId === cameraId) return cameraIdMapping[1].label;
        return '';
    }

    trackAnalytics = (isPlay: boolean = false, isDownload: boolean = false) => {
        const trackObj = {
            eventName: DASHCAM_RECORDING,
            feature: DASHCAM_ANALYTICS,
            qualityLevel: this.state.qualityLevel,
            selectedCamera:
                this.getSelectedCameraText(this.state.selectedCameraId ||
                    cameraIdMapping[0].cameraId),
            playRecordingClick: false,
            downloadRecordingClick: false,
        };

        if (isPlay) {
            trackObj.playRecordingClick = true;
        }

        if (isDownload) {
            trackObj.downloadRecordingClick = true;
        }
        analytics.track(DASHCAM_ANALYTICS, trackObj);
    }

    handleChangeTrackAnalytics = (selectedCameraId: number) => {
        const trackObj = {
            eventName: DASHCAM_RECORDING,
            feature: DASHCAM_ANALYTICS,
            qualityLevel: this.state.qualityLevel,
            selectedCamera:
                this.getSelectedCameraText(selectedCameraId),
            playRecordingClick: false,
            downloadRecordingClick: false,
        };
        analytics.track(DASHCAM_ANALYTICS, trackObj);
    }

    getStartTimeStamp = (timeToDisplay: string) => {
        let dateStr = this.state.selectedRecordingDate.format('YYYY-MM-DD');
        dateStr += ` ${moment(timeToDisplay, ['HH:mm:ss']).format('HH:mm')}`;
        const reminder = moment(timeToDisplay, ['HH:mm:ss']).format('ss');
        const selectedRecordingDate = moment.tz(dateStr, getAppUserSettings('userTz'));
        return selectedRecordingDate.unix() + (parseInt(reminder, 10) || 0);
    }

    updateRecordingControlData = (
        dashcamRecordingRanges: Array<Object>,
        index: number,
        recordingDate: string,
    ) => {
        const newIntervals = this.getRecordingIntervals(dashcamRecordingRanges[index]
            .intervals, recordingDate);
        const selectedCameraId = (dashcamRecordingRanges[index]
            && dashcamRecordingRanges[index].cameraId) || undefined;
        const recordingDateRanges = [];
        const cIndex = cameraIdMapping.findIndex(cItem => ((cItem.cameraId === selectedCameraId)));
        const selectedCameraName = (cIndex > -1) ? cameraIdMapping[cIndex].label : '';
        let selectedIntervals = [];
        let isRecordingAvailable = false;
        const timeSelected = {
            start: 0,
            startTimeStamp: moment().unix(),
            timeToDisplay: getTimeToDisplayAsPerAccSettings(0),
        };

        if (dashcamRecordingRanges.length > 0) {
            dashcamRecordingRanges[index].intervals.map((i) => {
                const recordingStartDate = convertDateTime(i.start, 'YYYY-MM-DD', true);
                const recordingEndDate = convertDateTime(i.end, 'YYYY-MM-DD', true);

                if ((recordingStartDate === recordingEndDate)
                    && !recordingDateRanges.includes(recordingStartDate)) {
                    recordingDateRanges.push(recordingStartDate);
                }

                return false;
            });

            selectedIntervals = this.getRecordingIntervalsByDate(
                recordingDate,
                newIntervals,
            );

            if (selectedIntervals.length > 0) {
                const selectedFirstStartDate = getTimeInMinutes(convertDateTime(selectedIntervals[0].start, 'H:mm'));
                isRecordingAvailable = true;
                timeSelected.start = selectedFirstStartDate;
                timeSelected.startTimeStamp =
                    this.getStartTimeStamp(convertDateTime(selectedIntervals[0].start, 'HH:mm:ss'));
                timeSelected.timeToDisplay =
                    getTimeToDisplayAsPerAccSettings(selectedFirstStartDate);
            }
        }

        this.setState({
            dashcamRecordingRanges,
            recordingDateRanges,
            selectedCameraId,
            selectedCameraName,
            selectedIntervals,
            isRecordingAvailable,
            timeSelected,
            showVideoPlayer: false,
        });
    }

    handleCameraChange = (selectedCameraId: number) => {
        const { dashcamRecordingRanges } = this.state;
        const dataIndex = dashcamRecordingRanges.findIndex(cItem =>
            ((cItem.cameraId === selectedCameraId)));
        this.handleChangeTrackAnalytics(selectedCameraId);

        if (dataIndex > -1) {
            this.updateRecordingControlData([...dashcamRecordingRanges], dataIndex, this.state.selectedRecordingDate.format('YYYY-MM-DD'));
        } else this.setState({ selectedCameraId, showVideoPlayer: false });
    };

    getLoader = () => (<AppLoader
        loaderStyle={{
            left: '50%',
            top: '50%',
            position: 'absolute',
        }}
    />);

    getRecordingSlots = (params: Object) => {
        if (isDashcamAuthenticated()) {
            getRecordingAvailability({ ...params }).then((response) => {
                this.setState({ isUpdating: false });
                if (response.status === 200) {
                    let dataIndex = 0;

                    if (this.state.selectedCameraId !== undefined) {
                        dataIndex = this.state.dashcamRecordingRanges.findIndex(cItem =>
                            ((cItem.cameraId === this.state.selectedCameraId)));
                    }

                    this.updateRecordingControlData([...(response.data || [])], dataIndex, this.state.selectedRecordingDate.format('YYYY-MM-DD'));
                } else {
                    this.props.showNotification(response.message);
                }
            });
        }
    }

    getRecordingIntervalsByDate = (
        selectedRecordingDate: string,
        allAvailableIntervals: any,
    ) => allAvailableIntervals.filter(i => (convertDateTime(i.start, 'YYYY-MM-DD', true).includes(selectedRecordingDate)
        && convertDateTime(i.end, 'YYYY-MM-DD', true).includes(selectedRecordingDate)));

    getRecordingIntervals = (intervals: any, selectedDate: string) => {
        const newIntervals = [];
        intervals.forEach((i) => {
            if ((!convertDateTime(i.start, 'YYYY-MM-DD', true).includes(selectedDate) && convertDateTime(i.end, 'YYYY-MM-DD', true).includes(selectedDate)) ||
                (convertDateTime(i.start, 'YYYY-MM-DD', true).includes(selectedDate) && !convertDateTime(i.end, 'YYYY-MM-DD', true).includes(selectedDate))) {
                newIntervals.push({ start: moment(i.start).tz(getAppUserSettings('userTz')).toISOString(), end: moment(i.start).tz(getAppUserSettings('userTz')).endOf('day').toISOString() });
                newIntervals.push({ start: moment(i.end).tz(getAppUserSettings('userTz')).startOf('day').toISOString(), end: moment(i.end).tz(getAppUserSettings('userTz')).toISOString() });
            } else newIntervals.push(i);
        });
        return newIntervals;
    }

    handleSliderMovement = (e: Object, selectedStartTime: number) => {
        const timeToDisplay = getTimeToDisplayAsPerAccSettings(selectedStartTime);
        const startTimeStamp = this.getStartTimeStamp(`${getTimeToDisplayAsPerAccSettings(selectedStartTime, true)}:00`);
        this.setState({
            timeSelected: { start: selectedStartTime, startTimeStamp, timeToDisplay },
        });
    }

    checkRecordingAvailibity = (isRecordingAvailable: boolean) =>
        (this.state.isRecordingAvailable !== isRecordingAvailable)
        && this.setState({ isRecordingAvailable });

    getVideoElement = () => this.playerRef.current && this.playerRef.current.getVideoElement();

    playPause = (isPlaying: boolean, isAutoPaused: boolean = false) => {
        const videoPlayer = this.getVideoElement();
        const { isRecordingAutoPaused } = this.state;

        if (!videoPlayer) return;

        if (isPlaying) {
            videoPlayPromise(videoPlayer);
        } else if (isRecordingAutoPaused) this.playRecordingVideo(isRecordingAutoPaused);
        else videoPlayer.play();

        this.setState({ isPlaying: !isPlaying, isRecordingAutoPaused: isAutoPaused });
    }

    playRecordingVideo = (isRecordingAutoPaused: boolean = false) => {
        let startTime = 0;
        const videoPlayer = this.getVideoElement();
        const streamDetails = `${this.state.selectedCameraName} : ${this.state.selectedRecordingDate.format('MMM D, YYYY')}  ${this.state.timeSelected.timeToDisplay}`;
        if (videoPlayer) videoPlayer.src = '';

        if (!isRecordingAutoPaused) {
            startTime = this.state.timeSelected.startTimeStamp;
        } else startTime = this.state.startTime + RECORDING_VIDEO_DURATION_IN_SEC;

        this.setState({ preparingRecording: true, streamDetails });
        this.trackAnalytics(true);

        getDeviceMediaTokenData(this.props.imei, (error, response) => {
            if (!error && response && response.mediaAddress && response.mediaToken) {
                const recordingURL = getMediaSourceUrl('recording', {
                    mediaAddress: response.mediaAddress,
                    mediaToken: response.mediaToken,
                    imei: this.props.imei,
                    cameraId: this.state.selectedCameraId,
                    startTime,
                    videoDuration: RECORDING_VIDEO_DURATION_IN_SEC,
                    qualityLevel: this.state.qualityLevel,
                });
                this.startAutoRefreshTimer(
                    recordingURL,
                    response.mediaAddress,
                    response.mediaToken,
                    startTime,
                    true,
                );
            } else this.props.showNotification(error || '');
        });
    }

    startAutoRefreshTimer = (
        recordingURL: string,
        mediaAddress: string,
        mediaToken: string,
        startTime: number,
        isFirst: boolean,
    ) => {
        this.autoRefreshTimer = setTimeout(
            () => prepareRecording(recordingURL).then((r) => {
                if (r.status === 200) {
                    this.clearTimer();
                    this.setState({
                        startTime,
                        playbackUrl: recordingURL,
                        showVideoPlayer: true,
                        preparingRecording: false,
                        isPlaying: true,
                    });
                } else {
                    this.startAutoRefreshTimer(
                        recordingURL,
                        mediaAddress,
                        mediaToken,
                        startTime,
                        false,
                    );
                }
            }),
            isFirst ? 0 : 5000,
        );
    };

    clearTimer = () => {
        if (this.autoRefreshTimer) clearTimeout(this.autoRefreshTimer);
    };

    downloadRecordingVideo = (e: Object) =>
        this.setState({ anchorEl: e.currentTarget }, () => { this.trackAnalytics(false, true); });

    handlePopOverClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleVideoQualityChange = (qualityLevel: string) => {
        this.setState({ qualityLevel, showVideoPlayer: false }, () => {
            this.trackAnalytics();
        });
    }

    renderDays = (
        date: Object,
        selectedDays: any,
        pickersDayProps: any,
    ) => {
        let className = '';
        if ((this.state.selectedRecordingMonth + 1) === parseInt(date.format('M'), 10)
            && this.state.recordingDateRanges.includes(date.format('YYYY-MM-DD'))) {
            className = customStyles.triangle_at_top_right;
        }
        return (
            <div className={className}>
                <PickersDay
                    {...pickersDayProps}
                    selected={this.state.pickedDate ?
                        moment(this.state.pickedDate).isSame(pickersDayProps.day, 'D') : false}
                />
            </div>
        );
    }

    handleRecDateChange = (selectedDate: Object) => {
        const { dashcamRecordingRanges } = this.state;
        const dataIndex = dashcamRecordingRanges.findIndex(cItem =>
            ((cItem.cameraId === this.state.selectedCameraId)));
        this.setState({ selectedRecordingDate: selectedDate, showVideoPlayer: false }, () => {
            if (dataIndex > -1) this.updateRecordingControlData([...dashcamRecordingRanges], dataIndex, selectedDate.format('YYYY-MM-DD'));
        });
        this.calendarCloseHandler();
    }

    handleOnChange = (pickedDate: Date) => { this.setState({ pickedDate }) }

    onMonthChange = (selectedDate: Object) => {
        let { selectedRecordingMonth, selectedRecordingYear } = this.state;
        const date = moment(selectedDate);
        selectedRecordingMonth = date.month();
        selectedRecordingYear = date.year();
        this.setState({ selectedRecordingYear, selectedRecordingMonth });
    }

    calendarOpenHandler = () => {
        const currentlySelectedMonthIndex = parseInt(this.state.selectedRecordingDate.format('M'), 10) - 1;
        const currentlySelectedYear = parseInt(this.state.selectedRecordingDate.format('YYYY'), 10);
        this.setState({ datePickerOpen: true });
        if (currentlySelectedMonthIndex !== this.state.selectedRecordingMonth) {
            this.setState({
                selectedRecordingMonth: currentlySelectedMonthIndex,
                selectedRecordingYear: currentlySelectedYear,
            });
        }
    }

    calendarCloseHandler = () => { this.setState({ datePickerOpen: false }) }

    render() {
        const {
            isRecordingAvailable,
            anchorEl,
            dashcamRecordingRanges,
            qualityLevel,
            datePickerOpen,
            selectedRecordingDate,
            pickedDate,
        } = this.state;
        const { selectedDevice } = this.props;
        let dashcamCameras = [];

        if (dashcamRecordingRanges.length > 0) {
            dashcamCameras = [...dashcamRecordingRanges];
        } else if (selectedDevice && selectedDevice.cameras) {
            dashcamCameras = [...selectedDevice.cameras];
        }

        if (this.state.isUpdating) { return this.getLoader(); }

        return (
            <Paper
                id="dashcam-camera-recording-container"
                className={customStyles.recording_container}
            >
                <Grid container justifyContent="space-around" alignItems="stretch">
                    <Grid item xs={12} className={customStyles.heading_container}>
                        <h6 className={customStyles.page_heading}>Recordings</h6>
                        <h5 className={customStyles.page_subheading}>{selectedDevice.name}</h5>
                    </Grid>
                    <Grid item xs={12} className={customStyles.filter_container}>
                        <Typography component="div">
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <MobileDatePicker
                                    sx={{ '& .MuiPickersToolbar-penIconButton': { display: 'none' } }}
                                    id="recording-date"
                                    components={{
                                        ActionBar: () => (
                                            <DialogActions>
                                                <Button
                                                    onClick={() => { this.calendarCloseHandler() }}
                                                >Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        this.handleRecDateChange(pickedDate);
                                                    }}
                                                >Ok
                                                </Button>
                                            </DialogActions>
                                        ),
                                    }}
                                    DialogProps={{
                                        onBackdropClick: () => { this.calendarCloseHandler() },
                                    }}
                                    renderDay={this.renderDays}
                                    leftArrowIcon={<LeftArrow />}
                                    rightArrowIcon={<RightArrow />}
                                    onMonthChange={this.onMonthChange}
                                    onOpen={this.calendarOpenHandler}
                                    label="Recording Date"
                                    renderInput={params => <TextField {...params} />}
                                    showToolbar
                                    toolbarTitle=""
                                    placeholder="mm/dd/yyyy"
                                    emptyLabel="mm/dd/yyyy"
                                    invalidLabel="mm/dd/yyyy"
                                    format="MM/DD/YYYY"
                                    value={selectedRecordingDate}
                                    onChange={this.handleOnChange}
                                    disableFuture
                                    className={customStyles.recordingDatePicker}
                                    minDate={moment().subtract(6, 'months')}
                                    open={datePickerOpen}
                                />
                            </LocalizationProvider>
                            <Select
                                value={this.state.selectedCameraId || cameraIdMapping[0].cameraId}
                                onChange={e =>
                                    this.handleCameraChange(e.target.value)}
                                className={customStyles.cameraDdl}
                                inputProps={{
                                    name: 'Select Camera',
                                    id: 'dashcam-recording-cameraId',
                                }}
                                displayEmpty
                            >
                                {dashcamCameras.map((c) => {
                                    const cam = cameraIdMapping.filter(cm =>
                                        (cm.cameraId === c.cameraId));
                                    return ((cam.length > 0) && (<MenuItem key={`camera-${c.cameraId}`} value={c.cameraId}>{cam[0].label}</MenuItem>));
                                })}
                            </Select>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={customStyles.seekbar_container}>
                        <SeekBar
                            selectedCameraId={this.state.selectedCameraId}
                            intervals={this.state.selectedIntervals}
                            timeSelected={this.state.timeSelected}
                            handleSliderMovement={this.handleSliderMovement}
                            checkRecordingAvailibity={this.checkRecordingAvailibity}
                        />
                        <div className={customStyles.playbackBtnContainer}>
                            {!this.state.preparingRecording &&
                                <IconButton
                                    disabled={!isRecordingAvailable}
                                    className={customStyles.playBtnContainer}
                                    onClick={() => this.playRecordingVideo()}
                                    size="large"
                                >
                                    <PlayArrow
                                        className={`${customStyles.playBtn} ${!isRecordingAvailable ? customStyles.disabledBtn : ''}`}
                                    />
                                </IconButton>
                            }
                            {this.state.preparingRecording &&
                                <AppLoader type="clip" width={18} height={18} />
                            }
                            <IconButton
                                disabled={!isRecordingAvailable}
                                className={customStyles.downloadBtnContainer}
                                onClick={e => this.downloadRecordingVideo(e)}
                                size="large"
                            >
                                <Publish className={`${customStyles.downloadBtn} ${!isRecordingAvailable ? customStyles.disabledBtn : ''}`} />
                            </IconButton>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={this.handlePopOverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <DownloadRecordings
                                    imei={this.props.imei}
                                    intervals={this.state.selectedIntervals}
                                    selectedCameraId={this.state.selectedCameraId}
                                    selectedCameraName={this.state.selectedCameraName}
                                    getStartTimeStamp={this.getStartTimeStamp}
                                    selectedRecordingDate={this.state.selectedRecordingDate}
                                    timeSelected={this.state.timeSelected}
                                    showNotification={this.props.showNotification}
                                    handleClose={this.handlePopOverClose}
                                />
                            </Popover>
                        </div>
                        <div className={customStyles.qualityBtnContainer}>
                            <Select
                                value={this.state.qualityLevel}
                                onChange={e => this.handleVideoQualityChange(e.target.value)}
                                className={customStyles.videoQualityDdl}
                                inputProps={{
                                    name: 'Select Video Quality',
                                    id: 'dashcam-recording-video-quality',
                                }}
                                disabled={!isRecordingAvailable}
                                sx={{ '.MuiSelect-select': { paddingRight: '0px !Important' }, '&.Mui-disabled:before': { borderBottom: '1px dotted rgba(0, 0, 0, 0.42)' } }}
                            >
                                <MenuItem value="lq">{NORMAL_QUALITY_TEXT}</MenuItem>
                                <MenuItem value="hq">{HIGH_QUALITY_TEXT}</MenuItem>
                            </Select>
                            <Tooltip
                                classes={{
                                    tooltip: customStyles.tooltip,
                                    popper: customStyles.tooltipPopper,
                                }}
                                title={
                                    <div>
                                        <div>{NORMAL_QUALITY_TEXT}: {NORMAL_QUALITY_TOOLTIP}</div>
                                        <div>{HIGH_QUALITY_TEXT}: {HIGH_QUALITY_TOOLTIP}</div>
                                    </div>
                                }
                            >
                                <Info className={`${customStyles.infoBtn} ${!isRecordingAvailable ? customStyles.disabledBtn : ''}`} />
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={customStyles.video_player_container}>
                        {this.state.showVideoPlayer &&
                            <React.Fragment>
                                <VideoPlayer
                                    playPause={(isPlaying, isAutoPaused) =>
                                        this.playPause(isPlaying, isAutoPaused)}
                                    isPlaying={this.state.isPlaying}
                                    ref={this.playerRef}
                                    recordingPlaybackUrl={this.state.playbackUrl}
                                    width="622px"
                                    checkLoading
                                    playbackRate={qualityLevel === 'lq' ? VIDEO_FAST_PLAYBACK_RATE : VIDEO_NORMAL_PLAYBACK_RATE}
                                    videoEnded={() => {
                                        if (qualityLevel === 'lq') this.playPause(true, true);
                                    }}
                                />
                                <span className={customStyles.stream_details}>
                                    {`Stream Details: ${this.state.streamDetails}`}
                                </span>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
                <lytx-recording-timeline
                    ref={this.timelineRef}
                    auth-token={getDashcamToken()}
                    imei={this.props.imei}
                    organization-id={getDashcamOrgId()}
                />
            </Paper>
        );
    }
}

export default CameraRecordings;
